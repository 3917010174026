// 
// timeline.scss
//
/************** vertical timeline **************/ 


.timeline {
    position: relative;
    width: 100%;
    padding: 30px 0;
  }
  
  .timeline .timeline-end,
  .timeline .timeline-start,
  .timeline .timeline-year {
    position: relative;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
  
  .timeline .timeline-end p,
  .timeline .timeline-start p,
  .timeline .timeline-year p {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 30px 0;
    text-align: center;
    background: url(../images/user-img.png);
    background-color: $primary;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    color: $white;
    text-transform: uppercase;
  }
  
  .timeline {
    .timeline-year {
      margin: 30px 0;
    }
    .timeline-continue {
      position: relative;
      width: 100%;
      padding: 60px 0;
      &:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        margin-left: -1px;
        background: $primary;
      }
    }
    .timeline-date {
      margin: 40px 10px 0 10px;
    }
  }
  
  .timeline .row.timeline-left,
  .timeline .row.timeline-right .timeline-date {
    text-align: right;
  }
  
  .timeline .row.timeline-right,
  .timeline .row.timeline-left .timeline-date {
    text-align: left;
  }
  
  .timeline .timeline-date::after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 45px;
    background: $primary;
    border-radius: 15px;
    z-index: 1;
  }
  
  .timeline .row.timeline-left .timeline-date::after {
    left: -7px;
  }
  
  .timeline .row.timeline-right .timeline-date::after {
    right: -7px;
  }
  
  .timeline .timeline-box,
  .timeline .timeline-launch {
    position: relative;
    display: inline-block;
    margin: 15px;
    padding: 20px;
    border: 1px solid $gray-200;
    border-radius: 6px;
  }
  
  .timeline .timeline-launch {
    width: 100%;
    margin: 15px 0;
    padding: 0;
    border: none;
    text-align: center;
    background: transparent;
  }
  
  .timeline .timeline-box::after,
  .timeline .timeline-box::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .timeline .row.timeline-left .timeline-box::after,
  .timeline .row.timeline-left .timeline-box::before {
    left: 100%;
  }
  
  .timeline .row.timeline-right .timeline-box::after,
  .timeline .row.timeline-right .timeline-box::before {
    right: 100%;
  }
  
  .timeline .timeline-launch .timeline-box::after,
  .timeline .timeline-launch .timeline-box::before {
    left: 50%;
    margin-left: -10px;
  }
  
  .timeline .timeline-box::after {
    top: 26px;
    border-color: transparent transparent transparent $light;
    border-width: 10px;
  }
  
  .timeline .timeline-box::before {
    top: 25px;
    border-color: transparent transparent transparent $gray-200;
    border-width: 11px;
  }
  
  .timeline .row.timeline-right .timeline-box::after {
    border-color: transparent $light transparent transparent;
  }
  
  .timeline .row.timeline-right .timeline-box::before {
    border-color: transparent $gray-200 transparent transparent;
  }
  
  .timeline .timeline-launch .timeline-box::after {
    top: -20px;
    border-color: transparent transparent $gray-200 transparent;
  }
  
  .timeline .timeline-launch .timeline-box::before {
    top: -19px;
    border-color: transparent transparent $light transparent;
    border-width: 10px;
    z-index: 1;
  }
  
  .timeline .timeline-launch .timeline-text {
    width: 100%;
  }
  
  @media (max-width: 767px) {
    .timeline .timeline-continue::after {
      left: 40px;
    }
  
    .timeline .timeline-end,
    .timeline .timeline-start,
    .timeline .timeline-year,
    .timeline .row.timeline-left,
    .timeline .row.timeline-right .timeline-date,
    .timeline .row.timeline-right,
    .timeline .row.timeline-left .timeline-date,
    .timeline .timeline-launch {
      text-align: left;
    }
  
    .timeline .row.timeline-left .timeline-date::after,
    .timeline .row.timeline-right .timeline-date::after {
      left: 47px;
    }
  
    .timeline .timeline-box,
    .timeline .row.timeline-right .timeline-date,
    .timeline .row.timeline-left .timeline-date {
      margin-left: 55px;
    }
  
    .timeline .timeline-launch .timeline-box {
      margin-left: 0;
    }
  
    .timeline .row.timeline-left .timeline-box::after {
      left: -20px;
      border-color: transparent $light transparent transparent;
    }
  
    .timeline .row.timeline-left .timeline-box::before {
      left: -22px;
      border-color: transparent $gray-200 transparent transparent;
    }
  
    .timeline .timeline-launch .timeline-box::after,
    .timeline .timeline-launch .timeline-box::before {
      left: 30px;
      margin-left: 0;
    }
  }
  