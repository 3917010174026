// 
// Widgets.scss
// 

// 
// Widgets.scss
// 

.dash-summary{
    border-top: 1px solid $gray-300;
}

.dash-main-border{
    border-bottom: 1px solid $gray-300;
}

.dash-info-widget{
    background: $dash-info-bg;
}

.dash-goal{
    border-left: 1px solid $gray-300;
}
@media (max-width: 768px) {
    .dash-goal{
        border-left: none;
    }
}

.carousel-indicators {
    bottom: -20px;
    button{
        background-color: $primary !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        margin: 5px;
        opacity: 0.5;
    }
}

.mini-stats-wid{
    .mini-stat-icon{
        overflow: hidden;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: rgba($white,.1);
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after{
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover{
        .mini-stat-icon{
            &::after{
                left: 60px;
            }
        }
    }
}

// Inbox widget

.inbox-wid{
    .inbox-list-item{
        a{
            color: $body-color;
            display: block;
            padding: 11px 0px;
            border-bottom: 1px solid $border-color;
        }

        &:first-child{
            a{
                padding-top: 0px;
            }
        }

        &:last-child{
            a{
                border-bottom: 0px;
            }
        }
    }
}


// activity widget

.activity-border{
    &:before{
        content: "";
        position: absolute;
        height: 38px;
        border-left: 2px dashed $gray-300;
        top: 40px;
        left: 0px;
    }
}

.activity-wid{
    margin-left: 16px;

    .activity-list{
        
        position: relative;
        padding: 0 0 33px 30px;
     
        .activity-icon{
            position: absolute;
            left: -20px;
            top: 0px;
            z-index: 2;
        }

        &:last-child{
            padding-bottom: 0px;
        }
    }

 
   
}
